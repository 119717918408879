<template>
  <q-btn
    :class="{ 'bg-white': !flat }"
    :dense="dense"
    :disable="disable"
    :flat="flat"
    :icon="icon"
    :label="label"
    :no-caps="noCaps"
    :padding="padding"
    :ripple="ripple"
    :size="size"
    square
    text-color="primary"
    :to="to"
    :type="type"
  >
    <slot />
  </q-btn>
</template>

<script setup lang="ts">
import type { QBtnProps } from 'quasar';

withDefaults(
  defineProps<{
    dense?: boolean;
    disable?: boolean;
    flat?: boolean;
    icon?: string;
    label?: string;
    noCaps?: boolean;
    padding?: string;
    ripple?: boolean;
    size?: string;
    to?: QBtnProps['to'];
    type?: string;
  }>(),
  {
    ripple: true,
  }
);
</script>
