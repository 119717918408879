<template>
  <div v-if="share">
    <q-btn
      :color="color"
      dense
      flat
      :href="url"
      icon="share"
      round
      :size="size"
      target="_blank"
      type="a"
      @click.stop="() => {}"
    >
      <q-tooltip
        :anchor="tooltipAnchor"
        :self="tooltipSelf"
        :transition-hide="tooltipTransitionHide"
        :transition-show="tooltipTransitionShow"
      >
        {{ tooltip }}
      </q-tooltip>
    </q-btn>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { SHARE_TYPES } from 'src/services/constants';
import { utcToLocalDateTime } from 'src/services/date';

export default {
  props: {
    size: {
      default: '12px',
      type: String,
    },
    share: {
      default: null,
      type: Object,
    },
    tooltipAnchor: {
      default: 'bottom middle',
      type: String,
    },
    tooltipSelf: {
      default: 'top middle',
      type: String,
    },
    tooltipTransitionHide: {
      default: 'jump-up',
      type: String,
    },
    tooltipTransitionShow: {
      default: 'jump-down',
      type: String,
    },
  },
  computed: {
    ...mapState('assets', ['shares']),
    ...mapState('env', ['locatorUrl']),
    color() {
      return this.share?.type === SHARE_TYPES.DEFAULT ? 'primary' : 'warning';
    },
    expiration() {
      return this.share?.expiration || null;
    },
    expirationString() {
      const { expiration } = this.share || {};
      return expiration ? utcToLocalDateTime(expiration) : '';
    },
    tooltip() {
      const { expirationString, share } = this;
      const { type: shareType } = share;
      const type = shareType === SHARE_TYPES.DEFAULT ? 'Shared' : 'Vehicle recovery';
      return expirationString ? `${type} until ${expirationString}` : '';
    },
    url() {
      return `${this.locatorUrl}/${this.share.id}`;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.q-btn__wrapper) {
  min-height: 0 !important;
  min-width: 0 !important;
}
</style>
