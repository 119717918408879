import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import { Dialog } from 'quasar';
import { toRaw } from 'vue';
import EditVehicleDialog from 'components/vehicles-page/EditVehicleDialog.vue';
import ErrorPayload from 'src/models/ErrorPayload';
import Schedule from 'src/models/Schedule';
import chain from 'src/services/chain';
import { dayjs } from 'src/services/date';
import errorService from 'src/services/error';
import { trackEvent } from 'src/services/intercom';
import { navigateToUrl } from 'src/services/navigation';
import { rafChunk } from 'src/services/raf';
import Transformer from 'src/transformers/Transformer';
import * as maintenanceActions from './actions/maintenance';

export function setVehicles(context, newVehicles) {
  return new Promise((resolve) => {
    // Clear out current vehicles
    context.commit('setVehicles', {});

    rafChunk(newVehicles, 250)
      .on('chunk', (vehicles) => {
        context.commit('updateVehicles', vehicles);
      })
      .on('finish', resolve);
  });
}

export async function initData(context) {
  if (context.state.vehiclesLoading === true) {
    return context.state.vehiclesLitePromise;
  }

  if (context.state.hasInitialized) {
    return null;
  }

  let resolveFetchPromise;
  context.commit(
    'fetchingVehicles',
    new Promise((resolve) => {
      resolveFetchPromise = resolve;
    })
  );

  // Get profile
  const profile = await context.dispatch('session/profile', null, { root: true });
  if (profile === null) {
    navigateToUrl({ name: 'login' });
    return null;
  }

  // Get user information
  const user = await context.dispatch('session/currentUser', null, {
    root: true,
  });
  const promise = context.rootState.app.broker.initData(user);

  const vehicles = await promise;
  context.commit('vehiclesFetched');

  // React to online/offline events
  window.addEventListener('offline', () => {
    context.rootState.app.broker.stopApiPolling();
  });
  window.addEventListener('online', () => {
    context.rootState.app.broker.startApiPolling();
  });

  // Verify search history results
  context.dispatch('search/verifyHistory', 'asset', { root: true });
  context.dispatch('search/verifyHistory', 'vehicle', { root: true });

  // wait for firebaseApiKey from env config
  await context.rootState.env.promise;

  const { firebaseApiKey } = context.rootState.env;

  if (user.firebase && firebaseApiKey) {
    const firebaseData = {
      accountId: user.accountId,
      firebase: {
        databaseUrl: user.firebase.databaseUrl,
        token: user.firebase.token,
        apiKey: firebaseApiKey,
      },
    };

    // Initialize firebase
    context.rootState.app.broker.initFirebase(firebaseData);
  }

  // Popuplate places
  context.dispatch('places/init', null, { root: true });

  resolveFetchPromise(vehicles);

  return vehicles;
}

/**
 * Retrieves battery history for a given key and updates that key in the store.
 *
 * @param {VehiclesStoreActionContext} context
 * @param {String} vehicleKey
 */
export async function getBatteryHistory(context, vehicleKey) {
  const history = await context.rootState.app.broker.getBatteryHistory(vehicleKey);

  if (!history.error) {
    context.commit('setBatteryLevelHistoryForKey', { key: vehicleKey, history });
  }

  return history;
}

/**
 * Retrieves schedules for the given account.
 *
 * @param {VehiclesStoreActionContext} context
 */
export async function getSchedules(context) {
  if (context.state.schedulesLoading === true) {
    return context.state.schedules;
  }

  context.commit('setSchedulesLoading', true);

  const schedulesData = await context.rootState.app.broker.fetchAndTransform({
    fn: 'getSchedules',
    transformationFn: 'transformArrayToObject',
  });

  const schedules = {};
  if (!schedulesData.error) {
    Object.keys(schedulesData).forEach((key) => {
      schedules[key] = new Schedule(schedulesData[key]);
    });
    context.commit('setSchedules', schedules);
  }

  context.commit('setSchedulesLoading', false);

  return schedules;
}

/**
 * Retrieves detailed vehicle data from the DataBroker.
 *
 * @param {VehiclesStoreActionContext} context
 * @param {String} vehicleKey
 * @returns {Promise<Object>}
 */
export async function getDetailedVehicle(context, vehicleKey) {
  if (context.state.detailedVehiclesLoading === true) {
    return context.state.detailedVehiclePromise;
  }

  const promise = context.rootState.app.broker.getDetailedVehicle(vehicleKey);
  context.commit('detailedVehiclesLoading', promise);
  const vehicle = await promise;

  // insert location from non-detailed data (is not present in detailed call)
  const { location } = context.state.vehicles[vehicleKey] || {};
  if (vehicle && location) {
    vehicle.location = { ...location };
  }

  if (vehicle?.key) {
    await context.commit('insertDetailedVehicle', vehicle);
  }

  context.commit('detailedVehiclesLoaded');

  return vehicle;
}

/**
 * Retrieves shares for the given vehicle.
 *
 * @param {VehiclesStoreActionContext} context
 * @param {String} vehicleKey
 */
export async function getLocationShares(context, vehicleKey) {
  context.commit('setVehicleSharesLoading', true);

  const shares = await context.rootState.app.broker.getLocationShares({
    id: vehicleKey,
    type: 'vehicle',
  });

  context.commit('setVehicleSharesLoading', false);

  return shares;
}

/**
 * Retrieves vehicle stats from the API.
 *
 * @param {VehiclesStoreActionContext} context
 * @param {Object} payload
 * @param {String} payload.vehicleKey
 * @param {String} payload.accountKey
 * @param {String} payload.startDate
 * @param {String} payload.endDate
 * @param {String} payload.grain
 * @returns {Object}
 */
export async function getVehicleStats(context, { vehicleKey, accountKey, startDate, endDate, grain }) {
  if (context.state.thirtyDayStatsLoading === true) {
    return null;
  }

  context.commit('setThirtyDayStatsLoading', true);

  let stats = null;
  try {
    const {
      results: [{ vehicleScoreData: statsByGrain }, { vehiclePeriodScoreData: aggregateStats }],
    } = await context.rootState.app.broker.fetchAndTransform({
      fn: 'getVehicleStats',
      params: {
        accountKey,
        vehicleKey,
        endDate,
        startDate,
        grain,
      },
    });
    stats = {
      statsByGrain,
      aggregateStats,
    };
    context.commit('insertThirtyDayStats', { vehicleKey, data: stats });
  } catch {
    // do nothing
  } finally {
    context.commit('setThirtyDayStatsLoading', false);
  }

  return stats;
}

export async function getStarterStatus(context, { vehicleKey, operationId }) {
  /** @type {Object.<"data", Object.<"commands", VehicleCommandIssuedRecord[]>>} */
  const { commands = [] } = await context.rootState.app.apiWorker.getVehicleCommands({
    vehicleKey,
    operationId,
  });

  const lastStarterCommand = chain(commands)
    .filter(({ action, status }) => {
      if (status !== 'success' && status !== 'pending') {
        return false;
      }
      if (['starter-enable', 'starter-disable'].includes(action) === false) {
        return false;
      }
      return true;
    })
    .orderBy('updated', 'desc')
    .value();

  // Determine if any commands are pending (only valid within last 5 minutes)
  const fiveMinutesAgo = dayjs().subtract(5, 'minute');
  const hasPendingStatus = lastStarterCommand.some(
    ({ status, timestamp }) => status === 'pending' && dayjs(timestamp).isAfter(fiveMinutesAgo)
  );
  if (hasPendingStatus) {
    return 'pending';
  }

  if (!lastStarterCommand.length) {
    return 'enabled';
  }

  return lastStarterCommand?.[0]?.action === 'starter-enable' ? 'enabled' : 'disabled';
}

/**
 * Toggles following a given vehicle.
 *
 * @param {VehiclesStoreActionContext} context
 * @param {Vehicle|Object} vehicle
 */
export function toggleFollow(context, vehicle) {
  const { followedVehicleKey } = context.state;
  const vehicleKey = _get(vehicle, 'key', null);

  const toggleToKey = followedVehicleKey === vehicleKey ? null : vehicleKey;

  context.commit('setFollowedVehicleKey', toggleToKey);

  if (toggleToKey === null) {
    return;
  }

  context.dispatch('map/focusOnAsset', { asset: context.getters.followedVehicle, zoom: true }, { root: true });
  context.dispatch('map/lockToIdealBounds', false, { root: true });
  // Hide the list (mobile view) when we start following a vehicle
  context.dispatch('map/closeListDialog', null, { root: true });
}

/**
 * Patches the vehicles using the given patch.
 *
 * @param {import('src/types/vehicles-store').VehiclesStoreActionContext} context
 * @param {Array} patch
 */
export async function updateVehicles(context, updates) {
  context.commit('updateVehicles', updates);

  /**
   * Only update focus and map layers when something has changed
   * - following a vehicle triggers a bounds update which triggers a vehicle update
   *   this is mainly to prevent endless panning to a vehicle when no real change occurs
   */
  if (context.state.followedVehicleKey) {
    context.dispatch('map/focusOnAsset', { asset: context.getters.followedVehicle, zoom: false }, { root: true });
  }

  /**
   * Handle special situations after filtering occurs.
   */
  if (context.rootState.filtering.filteringOccurred) {
    // Expand bounding box if all vehicles are out of view.
    if (
      context.rootGetters['assets/visibleAssetsInBounds'].length === 0 &&
      context.rootGetters['assets/visibleAssets'].length > 0
    ) {
      context.dispatch('map/fitToIdealBounds', {}, { root: true });
    }

    // Unselect selected vehicle if it is now filtered out
    if (context.rootState.assets.selectedKey !== null && !context.rootGetters['assets/selectedAsset'].isVisible) {
      navigateToUrl('/');
    }

    context.commit('filtering/filteringOccurred', false, { root: true });
  }
}

/**
 * Deletes a vehicle via the Data Broker.
 *
 * @param {VehiclesStoreActionContext} context
 * @param {String} key
 * @returns {Object|ErrorPayload}
 */
export async function deleteVehicle(context, vehicleKey) {
  const response = await context.rootState.app.broker.deleteVehicle(vehicleKey);

  if (response.error) {
    return new ErrorPayload(response.error);
  }

  return response;
}

/**
 * Creates/updates a vehicle using the given vehicle data.
 *
 * @param {VehiclesStoreActionContext} context
 * @param {Object} vehicle
 * @returns {Object|ErrorPayload}
 */
export async function saveVehicle(context, vehicle) {
  let fn = 'createVehicle';

  // TODO: Remove this once vehicle endpoint is migrated to v2
  // send an array with an empty string to clear all tags/groups
  if (Array.isArray(vehicle.tagKeys) && vehicle.tagKeys.length === 0) {
    vehicle.tagKeys = [''];
  }
  if (Array.isArray(vehicle.groupKeys) && vehicle.groupKeys.length === 0) {
    vehicle.groupKeys = [''];
  }

  // Get raw value of Vue Proxy
  vehicle.tagKeys = toRaw(vehicle.tagKeys);
  vehicle.groupKeys = toRaw(vehicle.groupKeys);

  const params = { data: { ...vehicle } };

  try {
    // updating an existing vehicle
    if (vehicle.key) {
      fn = 'updateVehicle';
      params.key = vehicle.key;
    }

    const response = await context.rootState.app.broker[fn](params);

    if (response.error) {
      return new ErrorPayload(response.error);
    }

    // force detailed vehicle update
    context.dispatch('getDetailedVehicle', response.key);

    return response;
  } catch (error) {
    console.error('Unable to save vehicle:', error);
    return new ErrorPayload(error.message);
  }
}

export async function changeVehiclesGroup(context, payload) {
  context.commit('updateVehiclesGroups', payload);
}

export async function createShare(context, payload) {
  const { source = '' } = payload;
  const shareTypeToIntercomEventMap = {
    'location-share': 'vehicle_location_shared',
    'recovery-mode': 'vehicle_recovery_mode_enabled',
    'paid-repossession': 'vehicle_paid_repossession_requested',
  };
  if (source && shareTypeToIntercomEventMap[source]) {
    trackEvent(shareTypeToIntercomEventMap[source]);
  }
  const data = Transformer.snakeCaseKeysDeep(payload);
  const response = await context.rootState.app.broker.fetchAndTransform({
    fn: 'createLocationShare',
    params: data,
    transformationFn: 'camelCaseKeysDeep',
  });
  return response;
}

/**
 *
 * @param {VehiclesStoreActionContext} context
 * @param {Object} payload
 * @param {String} payload.name
 * @param {String} payload.weekdayStart
 * @param {String} payload.weekdayEnd
 * @param {String} payload.weekendStart
 * @param {String} payload.weekendEnd
 * @returns {Schedule|ErrorPayload}
 */
export async function addSchedule(context, payload) {
  let result = {};

  try {
    const scheduleDetails = await context.rootState.app.broker.addVehicleSchedule(payload);

    const scheduleError = scheduleDetails.errors || scheduleDetails.error;

    if (scheduleError) {
      const error = new Error('Bad request');
      error.details = scheduleError;
      throw error;
    }

    const scheduleAdded = new Schedule(scheduleDetails);
    context.commit('addSchedule', scheduleAdded);
    result = scheduleAdded;
  } catch (error) {
    if (error.details) {
      console.error(error.details);
    }
    result = new ErrorPayload(
      errorService.text(error, {
        'New Schedule Info': payload,
        'API Errors': error.details,
      })
    );
  }

  return result;
}

/**
 * Opens the Edit Vehicle Dialog.
 *
 * @param {VehiclesStoreActionContext} context
 * @param {Object} payload
 * @param {String} payload.key
 * @param {Component} payload.parent
 * @returns {Dialog}
 */
export async function openEditVehicleDialog(context, key) {
  if (context.state.editDialog) {
    return context.state.editDialog;
  }

  const dialog = Dialog.create({
    component: EditVehicleDialog,
    componentProps: {
      vehicleKey: key,
    },
  }).onDismiss(() => {
    context.dispatch('closeEditVehicleDialog');
  });

  context.commit('setEditDialog', dialog);

  return dialog;
}

/**
 * Closes the Edit Vehicle Dialog and clears it from the store.
 *
 * @param {VehiclesStoreActionContext} context
 */
export async function closeEditVehicleDialog(context) {
  const { editDialog } = context.state;

  if (editDialog) {
    try {
      editDialog.hide();
    } catch {
      // will throw an error if dialog is already hidden, but there's no API to tell beforehand
    }
  }

  context.commit('setEditDialog', null);
}

/**
 * Removes the given vehicles (by key) from the store.
 *
 * @param {VehiclesStoreActionContext} context
 * @param {String[]} vehicleKeys
 */
export async function unsetVehicles(context, vehicleKeys) {
  const vehicles = { ...context.state.vehicles };
  vehicleKeys.forEach((key) => {
    delete vehicles[key];
  });
  context.commit('setVehicles', vehicles);
}

/**
 * Creates a vehicle checkin
 *
 * @param {VehiclesStoreActionContext} context
 * @param {Object} parameters
 */
export async function createCheckIn(context, params) {
  return context.rootState.app.apiWorker.createCheckIn(params);
}

/**
 * Return whether any recent vehicle commands are still pending for the given vehicle
 *
 * @param {VehiclesStoreActionContext} context
 * @param {String} vehicleKey
 * @returns {Promise<Boolean>}
 */
export async function hasPendingVehicleCommands(context, vehicleKey) {
  const data = await context.rootState.app.apiWorker.getVehicleCommands({ vehicleKey });

  if (data.error) {
    return data;
  }

  const { commands } = data;

  let fiveMinutesAgo;
  const hasPending = commands.some(({ status, timestamp }) => {
    const isPending = status === 'pending';
    if (isPending) {
      if (_isNil(fiveMinutesAgo)) {
        fiveMinutesAgo = dayjs().subtract(5, 'minute');
      }
      const ts = dayjs(timestamp);
      return ts.isAfter(fiveMinutesAgo);
    }
    return false;
  });

  return hasPending;
}

/**
 * Send vehicle commands
 *
 * @param {VehiclesStoreActionContext} context
 * @param {Object} payload
 * @param {"starter-enable" | "starter-disable"} payload.command
 * @param {string} payload.vehicleKey
 * @return {Promise<VehicleCommandIssuedData>}
 */
export async function sendVehicleCommand(context, { command, vehicleKey }) {
  return context.rootState.app.apiWorker.sendVehicleCommand({ command, vehicleKey });
}

/**
 * Reports the given vehicle as lost/stolen.
 *
 * @param {VehiclesStoreActionContext} context
 * @param {Object} payload
 * @param {String} payload.link
 * @param {String} payload.vehicleKey
 * @returns {Boolean} True if successful.
 */
export async function reportLostOrStolenVehicle(context, { link, vehicleKey }) {
  const { email, firstName, lastName } = context.rootState.session.currentUser;
  const vehicle = context.state.vehicles[vehicleKey];
  const { key: accountKey, id: accountId, nickname: accountName } = context.rootState.session.account;

  const params = {
    accountId,
    accountKey,
    accountName,
    link,
    nickname: context.state.vehicles[vehicleKey].nickname,
    reporter: `${firstName} ${lastName} <${email}>`,
    serial: vehicle.firstConnectedDevice.serial,
    vehicle_key: vehicleKey,
    yearMakeModel: vehicle.yearMakeModel,
  };

  return context.rootState.app.apiWorker.reportLostOrStolenVehicle(params);
}

/**
 * Retrieves the oldest dashcam media timestamps.
 *
 * @param {VehiclesStoreActionContext} context
 * @param {string} serial
 */
export async function getDashcamOldestMedia(context, serial) {
  /** @type {{ cabinTimestamp: number; roadTimestamp: number }} */
  const oldestMediaTimestamps = await context.rootState.app.broker.fetchAndTransform({
    fn: 'getDashcamOldestMedia',
    params: serial,
  });
  return oldestMediaTimestamps;
}

/**
 * Retrieves the oldest dashcam media timestamps.
 *
 * @param {VehiclesStoreActionContext} context
 * @param {string} serial
 */
export async function getDashcamVideoDates(context, serial) {
  /** @type {{ timestamps: number[] }} */
  const data = await context.rootState.app.broker.fetchAndTransform({
    fn: 'getDashcamVideoDates',
    params: serial,
  });
  return data.timestamps;
}

export const {
  createAccountMaintenanceCategory,
  createMaintenanceHistory,
  createMaintenanceReminder,
  deleteMaintenanceCategory,
  deleteMaintenanceHistory,
  deleteMaintenanceReminder,
  getMaintenanceCategories,
  getMaintenanceReminders,
  getMaintenanceHistory,
  updateMaintenanceReminder,
  updateServiceLog,
  upsertMaintenanceAction,
} = maintenanceActions;
