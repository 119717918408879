<template>
  <div class="row content">
    <div class="column items-center q-mr-sm">
      <AssetImage
        data-t="vehicleImage"
        :size="36"
        :status="asset.status"
        :type="type"
        :url="asset.imageUrl"
        @click="$emit('icon-click')"
      />
      <div class="relative full-width">
        <div class="absolute full-width row items-center justify-center no-wrap">
          <q-icon v-if="asset.hasDashcam" class="video-icon" color="dark" name="videocam" size="sm">
            <q-tooltip
              anchor="center right"
              self="center left"
              transition-hide="jump-left"
              transition-show="jump-right"
            >
              Dashcam Equipped
            </q-tooltip>
          </q-icon>
          <SharedVehicleIndicator
            v-if="asset.share"
            class="share-icon"
            :share="asset.share"
            tooltip-anchor="center right"
            tooltip-self="center left"
            tooltip-transition-hide="jump-left"
            tooltip-transition-show="jump-right"
          />
        </div>
      </div>
    </div>
    <div class="col" :class="{ tall: showName && showDriver }">
      <div
        class="condensed-details"
        :class="{ 'has-alerts': alerts.length || asset.maintenanceDue }"
        data-t="condensedDetails"
      >
        <div
          class="nickname left-side-text row justify-between no-wrap zubie-font"
          :class="{ 'no-name': !showName, expanded: isExpanded }"
          data-t="nickname"
          @click="$emit('nickname-click')"
        >
          <div class="column nickname__label" :class="{ 'ellipsis text-no-wrap': !isExpanded }">
            <div :class="{ ellipsis: !isExpanded, 'text-weight-bold': isExpanded }">
              <text-highlight v-if="showName && asset.nickname" :query="termsString" split-by-space>{{
                asset.nickname
              }}</text-highlight>
            </div>
            <div v-if="showName && !asset.nickname" class="text-dark text-italic" :class="{ ellipsis: !isExpanded }">
              Unnamed {{ isVehicle ? 'Vehicle' : 'Asset' }}
            </div>
            <ToolTip v-if="!isExpanded" :delay="750">{{ asset.nickname }}</ToolTip>
            <div
              v-if="asset.yearMakeModel || asset.type"
              class="text-caption text-grey-7"
              :class="{ ellipsis: !isExpanded }"
            >
              <text-highlight v-if="asset.yearMakeModel" :query="termsString" split-by-space>{{
                asset.yearMakeModel
              }}</text-highlight>
              <text-highlight v-if="asset.type" :query="termsString" split-by-space>{{
                asset.type.name
              }}</text-highlight>
            </div>
          </div>
        </div>

        <div v-if="friendlyTimestamp" class="text-grey-7">{{ friendlyTimestamp }}</div>

        <div
          v-if="showAddress && (asset.isMoving || asset.location)"
          class="left-side-text ellipsis"
          :class="{ expanded: isExpanded }"
          data-t="lastAddress"
        >
          <div v-if="asset.isMoving && !isExpanded" class="address row items-center">
            <q-icon name="navigation" size="0.9em" />
            <div>Currently driving...</div>
          </div>
          <div v-else-if="!isExpanded">
            <AddressString
              class="address"
              :class="{ ellipsis: !isExpanded }"
              data-t="addressString"
              :latitude="asset.location.latitude"
              :longitude="asset.location.longitude"
              :search-terms="searchTerms"
              show-icon
              @load="resolvedAddress = $event"
            >
              <ToolTip v-if="!isExpanded" :delay="750">{{ resolvedAddress }}</ToolTip>
            </AddressString>
          </div>
        </div>

        <DriverChip
          v-if="isVehicle && showDriver && !isExpanded"
          class="roboto q-mt-xs"
          data-t="nicknameHighlight"
          :dense="true"
          :driver="asset.driver"
          :search-terms="searchTerms"
        />
      </div>

      <div class="text-grey-9" data-t="details">
        <transition mode="out-in" name="slideHeight">
          <div v-if="isExpanded" class="details-container">
            <div class="details" data-t="expandedDetails">
              <span :class="{ 'animated-ellipsis': asset.isMoving }">{{ asset.statusDisplay }}</span>
              <span v-if="asset.isMoving && asset.location.speedMph" data-t="speed">
                ({{ speedLocalized(asset.location.speedMph, 'mph') }})
                <br />
              </span>
              <div
                v-else-if="!showAddress || (showAddress && isExpanded)"
                class="address text-cursor"
                @click.prevent.stop
              >
                <AddressString
                  data-t="addressString"
                  :latitude="asset.location.latitude"
                  :longitude="asset.location.longitude"
                  :search-terms="searchTerms"
                  show-icon
                />
              </div>
            </div>

            <div v-if="featureDashcamMessaging && asset.hasDashcam && $q.screen.lt.md" class="q-my-sm">
              <DashcamMessageButton :vehicle-key="asset.key" />
            </div>

            <DashcamLivePreviewWithPlaceholder
              v-if="asset.hasDashcam && $q.screen.lt.md && mayAccessDashcamMedia"
              :asset="asset"
              class="q-mt-xs q-mr-md q-mb-sm"
              :image-url="lastTripCabinImageUrl"
            />

            <QuickStats
              :battery-charge-level="asset.batteryChargeLevel"
              :battery-level="asset.deviceBatteryLevel"
              :battery-volts="asset.batteryVolts"
              class="q-mr-md"
              :class="{
                'quick-stats-border': isVehicle,
                'q-pb-sm q-mb-xs': isVehicle,
              }"
              data-t="quickStats"
              :fuel-level="asset.fuelLevel"
              :odometer="asset.odometer"
              :odometer-is-actual="asset.odometerIsActual"
              :odometer-um="asset.odometerUm"
              :type="type"
            />

            <MappedItemIdsAndDriver
              v-if="isVehicle || asset.firstConnectedDevice.serial"
              :asset="asset"
              class="q-mb-md"
              :search-terms="searchTerms"
            />

            <VehiclesListItemAlert
              v-for="alert in alerts"
              :key="alert.type"
              :asset="asset"
              class="q-mb-sm"
              :data="alert.data"
              data-t="alert"
              :type="alert.type"
            />
          </div>
        </transition>

        <div v-if="isExpanded" class="actions row justify-between">
          <div class="track-actions row items-center">
            <VehicleFollowButton v-if="canFollow" :size="14" :vehicle="asset" />
            <AssetZoomButton
              v-if="!canFollow && isConnected"
              :asset="asset"
              class="q-py-xs q-px-sm"
              :size="14"
              @click.stop="$emit('zoom-click')"
            />
            <TrackDeviceButton
              v-if="!isConnected && isVehicle"
              class="track-device-button"
              :size="14"
              :vehicle="asset"
            />
          </div>
          <div class="nav-actions row items-end self-end">
            <MappedItemButton
              class="popup-button min-height-none"
              icon-right="arrow_forward"
              label="Overview"
              :size="14"
              :to="overview"
              @click.stop
            />
            <MappedItemButton
              class="popup-button min-height-none"
              icon-right="arrow_forward"
              :label="asset.hasTripsDevice ? 'Trips' : 'Locations'"
              :size="14"
              :to="locations"
              @click.stop
            />
          </div>
        </div>
      </div>
    </div>

    <div class="absolute-top-right">
      <div class="row items-center q-mt-sm">
        <MappedItemAlertIcons
          :alerts="alerts"
          class="q-mr-sm"
          :is-vehicle="isVehicle"
          :maintenance-due="asset.maintenanceDue"
          :show-driver="showDriver"
        />
        <q-icon
          v-if="$q.screen.gt.sm"
          :class="{
            'expand-icon': true,
            'expand-icon--expanded': isExpanded,
          }"
          color="grey-7"
          data-t="expandCollapseIcon"
          name="keyboard_arrow_down"
          size="22px"
        />
        <MappedAssetsListItemMenu
          :asset="asset"
          class="q-mr-sm"
          data-t="menu"
          :menu-btn-padding="menuBtnPadding"
          :show-locations="showLocationsInMenu"
          :show-overview="showOverviewInMenu"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';
import { mapGetters, mapState } from 'vuex';
import AddressString from 'components/AddressString.vue';
import AssetImage from 'components/assets/AssetImage.vue';
import DriverChip from 'components/chips/DriverChip.vue';
import DashcamLivePreviewWithPlaceholder from 'components/dashcam/DashcamLivePreviewWithPlaceholder.vue';
import DashcamMessageButton from 'components/dashcam/DashcamMessageButton.vue';
import AssetZoomButton from 'components/map/AssetZoomButton.vue';
import MappedAssetsListItemMenu from 'components/map/MappedAssetsListItemMenu.vue';
import MappedItemAlertIcons from 'components/map/MappedItemAlertIcons.vue';
import MappedItemButton from 'components/map/MappedItemButton.vue';
import VehicleFollowButton from 'components/map/VehicleFollowButton.vue';
import ToolTip from 'components/ToolTip.vue';
import TrackDeviceButton from 'components/track-device/TrackDeviceButton.vue';
import SharedVehicleIndicator from 'components/vehicle/SharedVehicleIndicator.vue';
import MappedItemIdsAndDriver from 'components/vehicles-list/MappedItemIdsAndDriver.vue';
import QuickStats from 'components/vehicles-list/QuickStats.vue';
import VehiclesListItemAlert from 'components/vehicles-list/VehiclesListItemAlert.vue';
import Asset from 'src/models/Asset';
import Vehicle from 'src/models/Vehicle';
import * as assetAlerts from 'src/services/assetAlerts';
import { statuses, ASSET_TYPES } from 'src/services/constants';
import { speedLocalized } from 'src/services/locale';
import { setTimeoutPromise } from 'src/services/setTimeout';

export default {
  props: {
    index: Number,
    isExpanded: Boolean,
    menuBtnPadding: String,
    searchTerms: {
      type: Array,
      default: () => [],
    },
    showAddress: {
      type: Boolean,
      default: false,
    },
    showDriver: {
      type: Boolean,
      default: false,
    },
    showName: {
      type: Boolean,
      default: true,
    },
    showLocationsInMenu: {
      type: Boolean,
      default: true,
    },
    showOverviewInMenu: {
      type: Boolean,
      default: true,
    },
    asset: [Vehicle, Asset],
  },
  components: {
    AddressString,
    AssetImage,
    AssetZoomButton,
    DashcamLivePreviewWithPlaceholder,
    DashcamMessageButton,
    MappedItemButton,
    QuickStats,
    TrackDeviceButton,
    VehicleFollowButton,
    VehiclesListItemAlert,
    MappedAssetsListItemMenu,
    MappedItemAlertIcons,
    SharedVehicleIndicator,
    DriverChip,
    MappedItemIdsAndDriver,
    ToolTip,
  },
  emits: ['icon-click', 'nickname-click', 'resize-finished', 'zoom-click'],
  data() {
    return {
      resizeObserver: null,
      resolvedAddress: null,
      wasSelected: false,
    };
  },
  computed: {
    ...mapState('env', ['featureDashcamMessaging']),
    ...mapGetters('session', ['mayAccessDashcamMedia']),
    ...mapGetters('assets', {
      selectedAsset: 'selectedAsset',
    }),
    ...mapGetters('trips', ['lastTripCabinImageUrl', 'lastTripRoadImageUrl']),
    alerts() {
      return assetAlerts.check(this.asset);
    },
    canFollow() {
      return this.isVehicle && [statuses.MOVING, statuses.STOPPED].includes(this.asset.status);
    },
    friendlyTimestamp() {
      return this.asset.location?.friendlyTimestamp;
    },
    isConnected() {
      return this.asset.status !== statuses.DISCONNECTED;
    },
    images() {
      return {
        cabin: this.lastTripCabinImageUrl,
        road: this.lastTripRoadImageUrl,
      };
    },
    isVehicle() {
      return this.type === ASSET_TYPES.VEHICLE;
    },
    locations() {
      return { name: this.asset.tripsRoute, params: { key: this.asset.key } };
    },
    overview() {
      const name = this.isVehicle ? 'vehicle' : 'asset';
      return { name, params: { key: this.asset.key } };
    },
    termsString() {
      return this.searchTerms.join(' ');
    },
    type() {
      return this.asset instanceof Vehicle ? ASSET_TYPES.VEHICLE : ASSET_TYPES.ASSET;
    },
  },
  methods: {
    resizeFinished() {
      this.$emit('resize-finished');
      this.resizeObserver?.disconnect();
      this.resizeObserver = null;
    },
    speedLocalized,
  },
  watch: {
    isExpanded() {
      if (this.isExpanded) {
        this.resizeObserver = new ResizeObserver(
          _debounce(async () => {
            await setTimeoutPromise(250);
            this.resizeFinished();
          }, 250)
        );
        this.resizeObserver.observe(this.$el);
      } else if (this.resizeObserver) {
        this.resizeFinished();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$small-drawer-breakpoint: 420px;

.content {
  line-height: 1;
}

.asset {
  border-bottom: 1px solid $grey-5;
  background: white;
}

.selected {
  position: relative;
  z-index: 1;
  box-shadow: 0px 0 10px #888;
}

.expand-icon {
  transform: rotate(0deg);
  transition: 0.2s ease-out transform;
}

.tall .expand-icon {
  top: 24px;
}

.expand-icon--expanded {
  transform: rotate(90deg);
}

.left-side-text {
  max-width: 230px; // reserve room for expand/collapse and kebab menu
  white-space: nowrap;

  &.expanded {
    white-space: inherit;
  }

  .ellipsis {
    max-width: 100%;
  }
}

.has-alerts .left-side-text {
  max-width: 210px; // reserve room for expand/collapse and kebab menu
}

.nickname {
  margin-top: 3px;
  margin-bottom: 4px;
  font-size: 16px;

  &.no-name {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.nickname span + span {
  font-weight: 400;
}

.nickname__label {
  .ellipsis {
    max-width: 100%;
  }
}

.address {
  font-size: 12px;
  margin-top: 4px;
}

.details .address {
  display: flex;
  align-items: center;
  margin: 4px 0;
  font-size: inherit;

  :deep(.q-icon) {
    margin-left: -3px;
    font-size: 1.3em;
  }
}

.video-icon,
.share-icon,
.share-icon :deep(.q-btn) {
  height: 18px;
  min-width: 0;
  min-height: 0;
}

.details {
  line-height: 1.5;
}

.quick-stats-border {
  border-bottom: 2px solid $grey-5;
}

.text-cursor {
  cursor: text;
}

.slideHeight-enter-active,
.slideHeight-leave-active {
  max-height: 300px;
  overflow: hidden;
  transition: max-height 0.5s;
  will-change: max-height;
}

.slideHeight-enter,
.slideHeight-leave-to {
  max-height: 0px;
}

.actions {
  position: fixed;
  bottom: calc(env(safe-area-inset-bottom) + 53px);
  max-width: 332px;
  padding-bottom: 16px;
  width: 80vw;
  background: white;

  @media (min-width: $breakpoint-sm) {
    position: static;
    border-top: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
    width: auto;
  }
}

.track-actions {
  button {
    left: -8px;
  }
}

.track-device-button {
  @media (min-width: $small-drawer-breakpoint) {
    margin-bottom: 0;
    margin-top: -4px;
  }
}
</style>
