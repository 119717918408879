import _cloneDeep from 'lodash/cloneDeep';
import Vehicle from 'src/models/Vehicle';
import * as maintenanceMutations from './mutations/maintenance';

export function setBatteryLevelHistoryForKey(state, { key, history }) {
  state.batteryLevelHistory = {
    ...state.batteryLevelHistory,
    [key]: history,
  };
}

export function fetchingVehicles(state, promise) {
  state.vehiclesLoading = true;
  state.vehiclesLitePromise = promise;
}

/**
 * Replaces the state's vehicles and creates Vehicle objects for each.
 *
 * @param {Object} state
 * @param {Object.<string, Object>} vehicles
 */
export function setVehicles(state, vehicles) {
  const vehiclesObj = {};

  Object.values(vehicles).forEach((vehicle) => {
    vehiclesObj[vehicle.key] = new Vehicle(vehicle);
  });

  state.vehicles = Object.freeze(vehiclesObj);
}

export function vehiclesFetched(state) {
  state.vehiclesLoading = false;
  state.vehiclesLitePromise = null;
  state.hasInitialized = true;
}

/**
 * Updates the state's vehicles and creates Vehicle objects for each.
 *
 * @param {Object} state
 * @param {Object.<string, Object>} vehicles
 */
export function updateVehicles(state, vehicles) {
  const vehiclesObj = {};

  Object.values(vehicles).forEach((vehicle) => {
    vehiclesObj[vehicle.key] = new Vehicle(vehicle);
  });

  state.vehicles = Object.freeze({ ...state.vehicles, ...vehiclesObj });
}

/**
 * Sets group data for vehicles that include the given member keys.
 *
 * @param {Object} state
 * @param {Object.<string, Array>} data
 */
export function updateVehiclesGroups(state, data) {
  const clone = _cloneDeep(state.vehicles);

  data.members.forEach((key) => {
    // Only update vehicles that exist
    // - asset keys may be included
    if (clone[key]) {
      clone[key].groups = data.groups;
      clone[key].groupKeys = data.groupKeys;
    }
  });

  setVehicles(state, clone);
}

export function setSchedules(state, schedules) {
  state.schedules = schedules;
}

export function setSchedulesLoading(state, value) {
  state.schedulesLoading = value;
}

export function addSchedule(state, schedule) {
  state.schedules = {
    ...state.schedules,
    [schedule.key]: schedule,
  };
}

/**
 * Updates the detailed vehicles with the given vehicle and creates a Vehicle object.
 *
 * @param {Object} state
 * @param {Object} vehicle
 */
export function insertDetailedVehicle(state, vehicle) {
  state.detailedVehicles = {
    ...state.detailedVehicles,
    ...{ [vehicle.key]: new Vehicle(vehicle) },
  };
}

export function detailedVehiclesLoaded(state) {
  state.detailedVehiclesLoading = false;
}

export function detailedVehiclesLoading(state, promise) {
  state.detailedVehiclesLoading = true;
  state.detailedVehiclePromise = promise;
}

export function insertThirtyDayStats(state, payload) {
  const { vehicleKey, data } = payload;
  state.thirtyDayStats = { ...state.thirtyDayStats, ...{ [vehicleKey]: data } };
}

export function setThirtyDayStatsLoading(state, value) {
  state.thirtyDayStatsLoading = value;
}

export function setFollowedVehicleKey(state, vehicleKey) {
  state.followedVehicleKey = vehicleKey;
}

export function removeVehicle(state, keyToRemove) {
  const clone = { ...state.vehicles };
  delete clone[keyToRemove];
  state.vehicles = clone;
}

export const {
  addAccountMaintenanceCategory,
  addMaintenanceHistoryForKey,
  addMaintenanceReminderForKey,
  deleteAccountMaintenanceCategory,
  deleteMaintenanceHistoryForKey,
  deleteMaintenanceReminderForKey,
  setAccountMaintenanceCategories,
  setMaintenanceHistoryForKey,
  setMaintenanceHistoryLoading,
  setMaintenanceRemindersForKey,
  setMaintenanceRemindersLoading,
  updateMaintenanceReminder,
  updateServiceLog,
} = maintenanceMutations;

export function setEditDialog(state, dialog) {
  state.editDialog = dialog;
}

export function setVehicleSharesLoading(state, isLoading) {
  state.vehicleSharesLoading = isLoading;
}
