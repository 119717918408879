import { createStore } from 'vuex';

import app from './app';
import apps from './apps';
import assets from './assets';
import debug from './debug';
import devices from './devices';
import env from './env';
import events from './events';
import filtering from './filtering';
import geocoding from './geocoding';
import groups from './groups';
import image from './image';
import lightbox from './lightbox';
import map from './map';
import notifications from './notifications';
import passes from './passes';
import places from './places';
import search from './search';
import session from './session';
import subscriptions from './subscriptions';
import tags from './tags';
import trips from './trips';
import users from './users';
import vehicleStyle from './vehicle-style';
import vehicles from './vehicles';

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation
 */

const Store = createStore({
  modules: {
    app,
    apps,
    assets,
    debug,
    devices,
    env,
    events,
    filtering,
    geocoding,
    groups,
    image,
    lightbox,
    map,
    notifications,
    passes,
    places,
    search,
    session,
    subscriptions,
    tags,
    trips,
    users,
    vehicleStyle,
    vehicles,
  },
  // enable strict mode (adds overhead!)
  // for dev mode only
  strict: Boolean(process.env.APP_URL?.includes('-l.zubie')),
});

/**
 * @returns {import('src/types/store').RootStore}
 */
export default function defaultExport(/* { ssrContext } */) {
  return Store;
}

export { Store };
