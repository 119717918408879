import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import objectSupport from 'dayjs/plugin/objectSupport';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(objectSupport);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

function makeFriendlyTimestamp(timestamp) {
  let dateString;

  if (!timestamp) {
    return timestamp;
  }

  if (!dayjs.isDayjs(timestamp)) {
    timestamp = dayjs(timestamp);
  }

  const today = dayjs();

  if (timestamp.isSame(today, 'day')) {
    dateString = 'Today';
  } else if (today.subtract(1, 'day').isSame(timestamp, 'day')) {
    dateString = 'Yesterday';
  } else {
    dateString = timestamp.format('M/D/YYYY');
  }

  return `${dateString} ${timestamp.format('h:mm A')}`;
}

/**
 * Formats a given date string to M/D/YYYY format.
 *
 * @param {string} dateString
 */
function monthDayYear(dateString) {
  return dayjs(dateString).format('M/D/YYYY');
}

/**
 * Returns a Dayjs instance using the current date/time.
 *
 * @returns {Dayjs} Dayjs instance
 */
function now() {
  return dayjs();
}

/**
 * Converts the given seconds to a days/hours/minutes phrase.
 *
 * @param {Number} seconds
 * @returns {String} x_day(s) x_hr(s) x_min(s)
 */
function secondsToDaysHoursMinutes(seconds) {
  const SECONDS_PER_MINUTE = 60;
  const SECONDS_PER_HOUR = SECONDS_PER_MINUTE * 60;
  const SECONDS_PER_DAY = SECONDS_PER_HOUR * 24;
  const days = Math.floor(seconds / SECONDS_PER_DAY);
  const hours = Math.floor((seconds - days * SECONDS_PER_DAY) / SECONDS_PER_HOUR);
  const minutes = Math.floor((seconds - (hours * SECONDS_PER_HOUR + days * SECONDS_PER_DAY)) / SECONDS_PER_MINUTE);

  const daysHoursMins = [];

  if (days > 0) {
    daysHoursMins.push(`${days}${getLabel(days, 'day')}`);
  }

  if (hours > 0) {
    daysHoursMins.push(`${hours}${getLabel(hours, 'hr')}`);
  }

  if (minutes > 0) {
    daysHoursMins.push(`${minutes}${getLabel(minutes, 'min')}`);
  }

  return daysHoursMins.join(' ');

  function getLabel(value, label) {
    if (value > 1) {
      return ` ${label}s`;
    }
    if (value === 1) {
      return ` ${label}`;
    }
    return '';
  }
}

/**
 * Converts the given seconds to an hours and minutes phrase.
 *
 * @param {number} seconds
 * @returns {string} x_hr(s) x_min(s)
 */
function secondsToHoursMinutes(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.round((seconds - hours * 3600) / 60);

  const hoursAndMins = [];

  if (hours > 0) {
    hoursAndMins.push(`${hours}${getLabel(hours, 'hr')}`);
  }

  if (minutes > 0) {
    hoursAndMins.push(`${minutes}${getLabel(minutes, 'min')}`);
  }

  if (hours === 0 && minutes === 0) {
    const intSeconds = Math.floor(seconds);
    hoursAndMins.push(`${intSeconds}${getLabel(intSeconds, 'second')}`);
  }

  if (hours === 0 && minutes === 0 && seconds === 0) {
    return '0 seconds';
  }

  return hoursAndMins.join(' ');

  function getLabel(value, label) {
    if (value > 1) {
      return ` ${label}s`;
    }
    if (value === 1) {
      return ` ${label}`;
    }
    return '';
  }
}

/**
 * Converts the given seconds to a days/hours/minutes phrase.
 *
 * @param {String} timestamp1
 * @param {String} timestamp2
 * @returns {String} x_day(s) x_hr(s) x_min(s)
 */
function timestampsToDaysHoursMinutes(ts1, ts2) {
  const start = dayjs(ts1);
  const end = dayjs(ts2);
  const durationInSeconds = end.diff(start, 'second');
  return secondsToDaysHoursMinutes(durationInSeconds);
}

/**
 * Returns a Dayjs object using the start of today's date.
 *
 * @returns {Dayjs}
 */
function todayStart() {
  return dayjs().startOf('day');
}

/**
 * Returns a Dayjs object using the end of today's date.
 *
 * @returns {Dayjs}
 */
function todayEnd() {
  return dayjs().endOf('day');
}

/**
 * Converts the given UTC timestamp into a formatted local time string.
 * @todo Write tests for this
 *
 * @param {string} utcTimestamp
 * @param {boolean} includeSeconds
 */
function utcToLocalDateTime(utcTimestamp, includeSeconds = false) {
  let dateString;

  if (!utcTimestamp) {
    return utcTimestamp;
  }

  const ts =
    typeof utcTimestamp === 'string' || typeof utcTimestamp === 'number'
      ? dayjs.utc(utcTimestamp).local()
      : utcTimestamp;

  const today = dayjs();

  if (ts.isSame(today, 'day')) {
    dateString = 'Today';
  } else if (today.subtract(1, 'day').isSame(ts, 'day')) {
    dateString = 'Yesterday';
  } else {
    dateString = ts.format('M/D/YY');
  }

  return `${dateString} ${ts.format(`h:mm${includeSeconds ? ':ss' : ''} A`)}`;
}

/**
 * Converts a given UTC time into a local time "h:mm A" format.
 *
 * @param {string} timeString
 */
function utcToLocalTimestamp(timeString, includeSeconds = false) {
  return dayjs
    .utc(timeString)
    .local()
    .format(`h:mm${includeSeconds ? ':ss' : ''} A`);
}

export {
  dayjs,
  makeFriendlyTimestamp,
  monthDayYear,
  now,
  secondsToDaysHoursMinutes,
  secondsToHoursMinutes,
  timestampsToDaysHoursMinutes,
  todayStart,
  todayEnd,
  utcToLocalDateTime,
  utcToLocalTimestamp,
};
