<template>
  <q-card v-show="share" :class="cardClasses" :flat="flat">
    <div v-if="!loading">
      <q-card-section :class="cardSectionClasses">
        <q-input v-if="url" v-model="url" filled label="Zubie Location Link" readonly>
          <template v-slot:after>
            <q-btn dense flat icon="file_copy" round @click="copy" />
          </template>
        </q-input>
        <div class="text-caption q-my-sm">{{ message }}</div>
        <q-btn
          class="q-mb-sm"
          color="primary"
          :href="url"
          icon-right="open_in_new"
          :label="buttonText"
          size="md"
          squard
          target="_blank"
          type="a"
        />
        <slot name="after" />
      </q-card-section>
    </div>
  </q-card>
</template>
<script>
import { mapState } from 'vuex';
import { copy } from 'src/services/clipboard';
import { SHARE_TYPES } from 'src/services/constants';
import { dayjs, utcToLocalDateTime } from 'src/services/date';

export default {
  name: 'ExistingShareCard',
  props: {
    buttonText: {
      default: 'View Location Link',
      type: String,
    },
    cardClasses: {
      default: 'q-px-sm q-py-none',
      type: String,
    },
    cardSectionClasses: {
      default: 'q-pt-none',
      type: String,
    },
    flat: {
      default: false,
      type: Boolean,
    },
    id: {
      reqired: true,
      type: String,
    },
    limit: {
      default: 1,
      type: Number,
    },
    source: {
      reqired: true,
      validator(value) {
        return Object.values(SHARE_TYPES).includes(value);
      },
    },
    type: {
      reqired: true,
      validator(value) {
        return ['asset', 'account', 'user', 'source', 'vehicle'].includes(value);
      },
    },
  },
  data() {
    return {
      loading: false,
      shares: [],
    };
  },
  computed: {
    ...mapState('app', ['broker']),
    ...mapState('assets', ['assets']),
    ...mapState('env', ['locatorUrl']),
    ...mapState('users', ['users']),
    ...mapState('vehicles', ['vehicles']),
    asset() {
      const { assets, share, vehicles } = this;
      if (share) {
        const { assetKey = '', vehicleKey = '' } = share;
        if (assetKey) {
          return assets[assetKey]?.nickname || '';
        }
        return vehicles[vehicleKey]?.nickname || '';
      }
      return '';
    },
    expiration() {
      const { share } = this;
      if (share) {
        return utcToLocalDateTime(dayjs(share.expiration));
      }
      return '';
    },
    message() {
      const { expiration, user } = this;
      if (expiration) {
        return user ? `Shared until ${expiration} by ${user}` : `Shared until ${expiration}`;
      }
      return user ? `Shared by ${user}` : '';
    },
    share() {
      return this.shares[0] || null;
    },
    url() {
      const { id } = this.share || {};
      return id ? `${this.locatorUrl}/${id}` : undefined;
    },
    user() {
      const { share, users } = this;
      if (share && share.creatorUserKey) {
        const user = users[share.creatorUserKey];
        return `${user?.firstName || ''} ${user?.lastName || ''}`;
      }
      return '';
    },
  },
  methods: {
    copy() {
      copy(this.url).catch(() => {});
    },
    async getShare() {
      this.loading = true;

      const { id, limit, type } = this;

      const shares = await this.broker.getLocationShares({
        id,
        type,
        limit,
      });

      this.loading = false;

      this.shares = shares.filter((share) => share.type === this.source);
      this.$emit('loaded', this.shares);
    },
  },
  mounted() {
    const { getShare, id, type } = this;
    if (id && type) {
      getShare();
    }
  },
  watch: {
    id() {
      this.getShare();
    },
    type() {
      this.getShare();
    },
  },
};
</script>
