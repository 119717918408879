/**
 * @param {AppStoreState} state
 */
export function idbReady(state) {
  state.idbReadyResolve();
}

/**
 * @param {AppStoreState} state
 */
export function ready(state) {
  state.readyResolve();
}

/**
 * @param {AppStoreState} state
 */
export function routerReady(state) {
  state.routerReadyResolve();
}

/**
 * @param {AppStoreState} state
 * @param {boolean} isOpen
 */
export function setAccountSettingsDialogState(state, isOpen) {
  state.accountSettingsDialogOpen = isOpen;
}

/**
 * @param {AppStoreState} state
 * @param {import('src/workers/Api').default} worker
 */
export function setApiWorker(state, worker) {
  state.apiWorker = worker;
}

/**
 * @param {AppStoreState} state
 * @param {import('src/workers/DataBroker').default} broker
 */
export function setBroker(state, broker) {
  state.broker = broker;
}

/**
 * @param {AppStoreState} state
 * @param {import('@capacitor/core').DeviceInfo} deviceInfo
 */
export function setDeviceInfo(state, deviceInfo) {
  state.deviceInfo = deviceInfo;
}

/**
 * @param {AppStoreState} state
 * @param {boolean} isFilterRoute
 */
export function setFilterableRoute(state, isFilterRoute) {
  state.isFilterRoute = isFilterRoute;
}

/**
 * @param {AppStoreState} state
 * @param {boolean} isOnDetailsPage
 */
export function setIsOnDetailsPage(state, isOnDetailsPage) {
  state.isOnDetailsPage = isOnDetailsPage;
}

/**
 * @param {AppStoreState} state
 * @param {boolean} isLiveMapRoute
 */
export function setLiveMapRoute(state, isLiveMapRoute) {
  state.isLiveMapRoute = isLiveMapRoute;
}

/**
 * @param {AppStoreState} state
 * @param {boolean} isOpen
 */
export function setMainNavOpen(state, isOpen) {
  state.mainNavOpen = isOpen;
}

/**
 * @param {AppStoreState} state
 * @param {string} title
 */
export function setMobileTitle(state, title) {
  state.mobileTitle = title;
}

/**
 * @param {AppStoreState} state
 * @param {boolean} isPending
 */
export function setMobileTitlePending(state, isPending) {
  state.mobileTitlePending = Boolean(isPending);
}

/**
 * @param {AppStoreState} state
 * @param {IosNotchSizes} sizes
 */
export function setNotchSizes(state, sizes) {
  state.notchSizes = { ...state.notchSizes, ...sizes };
}

/**
 * @param {AppStoreState} state
 * @param {'portrait-primary' | 'landscape-primary' | 'landscape-secondary'} orientation
 */
export function setOrientation(state, orientation) {
  state.orientation = orientation;
}

/**
 * @param {AppStoreState} state
 */
export function workersReady(state) {
  state.workersReadyResolve();
}

/**
 * @param {AppStoreState} state
 * @param {number} newOpenDialogCount
 */
export function setOpenZubieDialogCount(state, newOpenDialogCount) {
  state.openDialogCount = newOpenDialogCount;
}

/**
 * @param {AppStoreState} state
 */
export function didTakeOff(state) {
  state.takeOff = true;
}

/**
 * @param {AppStoreState} state
 * @param {Function}
 */
export function pollingError(state, dismiss) {
  state.pollingErrorDismiss = dismiss;
}

/**
 * @param {AppStoreState} state
 */
export function clearPollingError(state) {
  state.pollingErrorDismiss = null;
}
