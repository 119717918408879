<template>
  <q-drawer
    ref="drawer"
    content-class="bg-dark"
    dark
    no-swipe-open
    overlay
    persistent
    side="right"
    @hide="setMainNavOpen(false)"
    @show="onShow"
  >
    <q-list class="relative">
      <q-expansion-item
        v-if="isAuthenticated"
        :caption="currentUser.email"
        class="zubie-font text-white text-weight-bold"
        dark
        data-t="header"
        :default-opened="false"
        :disable="otherAccounts.length === 0"
        :hide-expand-icon="otherAccounts.length === 0"
        :label="account.nickname"
        @click.stop
      >
        <MainNavigationItem
          v-for="account in otherAccounts"
          :key="account.id"
          data-t="accountsListLink"
          icon="switch_account"
          :label="account.nickname"
          @click.stop="switchAccount(account.id)"
        />
      </q-expansion-item>

      <q-separator color="white" />

      <MainNavigationItem
        v-if="isAuthenticated && canAccessRoute('/')"
        data-t="liveMapLink"
        icon="near_me"
        label="Live Map"
        to="/"
      />

      <!-- Driver w/ Access Dashboard -->
      <MainNavigationItem
        v-if="isAuthenticated && isIndividual"
        data-t="dashboardLink"
        icon="bar_chart"
        label="Dashboard"
        to="/reports/dashboard/106"
      />

      <!-- Admin & Viewers Dashboard -->
      <MainNavigationItem
        v-if="isAuthenticated && canAccessRoute('/dashboard') && $q.screen.gt.sm && (isFleet || isRental)"
        data-t="dashboardLink"
        :href="isFleet ? '/reports?report=/dashboard/11' : '/reports?report=/dashboard/33'"
        icon="bar_chart"
        label="Dashboard"
      />

      <MainNavigationItem
        v-if="isAuthenticated && canAccessRoute('/reports') && $q.screen.gt.sm"
        data-t="reportsLink"
        label="Reports"
        to="/reports"
      >
        <template #icon>
          <q-icon class="q-mr-sm rotate-270" name="note" size="1.5rem" />
        </template>
      </MainNavigationItem>

      <MainNavigationItem
        v-if="isAuthenticated && canAccessRoute('/notifications') && $q.screen.xs"
        data-t="notificationsLink"
        icon="notifications"
        label="Notifications"
        to="/notifications"
      >
        <template #icon>
          <q-icon class="q-mr-sm" name="notifications" size="1.5rem">
            <NotificationsBadge v-if="areRecentUnread && !$q.screen.gt.xs" size="6px" />
          </q-icon>
        </template>
      </MainNavigationItem>

      <SmartMaintenanceLink v-if="isAllowedCarAdvise && $q.screen.gt.sm" />

      <MainNavigationItem
        v-if="isAuthenticated && canAccessRoute('/asset-recovery')"
        data-t="assetRecoveryLink"
        icon="input"
        label="Asset Recovery"
        to="/asset-recovery"
      />

      <MainNavigationItem
        v-if="isFleet && hasPermission('isAllowedToCheckIn')"
        data-t="checkInLink"
        icon="how_to_reg"
        label="Check In"
        to="/check-in"
      />

      <MainNavigationItem v-if="isAuthenticated && isIndividual" icon="directions_car" label="Feed" to="/feed">
        <template #icon>
          <EventsFeedIcon class="q-mr-sm" size="1.5rem" />
        </template>
      </MainNavigationItem>

      <q-separator v-if="isAuthenticated && !currentUser.isIndividual()" color="white" data-t="vehiclesSeparator" />

      <q-expansion-item
        v-if="isAuthenticated && !currentUser.isIndividual()"
        class="zubie-font text-white text-weight-bold"
        data-t="vehiclesListItem"
        default-opened
        label="Fleet Data"
        @click.stop
      >
        <MainNavigationItem
          v-if="canAccessRoute('/vehicles-assets')"
          data-t="vehiclesListLink"
          icon="directions_car"
          label="Vehicles &amp; Assets"
          :to="{ name: 'vehiclesAssets' }"
        />

        <MainNavigationItem
          v-if="canAccessRoute('/devices')"
          data-t="devicesLink"
          icon="developer_board"
          label="Devices"
          to="/devices"
        />

        <MainNavigationItem
          v-if="canAccessRoute('/places')"
          data-t="placesLink"
          icon="place"
          label="Places"
          to="/places"
        />

        <MainNavigationItem
          v-if="canAccessRoute('/users')"
          data-t="usersLink"
          icon="people"
          label="Users"
          to="/users"
        />
      </q-expansion-item>

      <q-separator v-if="isAuthenticated && !currentUser.isIndividual()" color="white" data-t="settingsSeparator" />

      <q-expansion-item
        v-if="isAuthenticated && !currentUser.isIndividual()"
        class="zubie-font text-white text-weight-bold"
        data-t="settingsItem"
        default-opened
        label="Settings"
        @click.stop
      >
        <MainNavigationItem
          v-if="canAccessRoute('/users')"
          data-t="myProfileLink"
          icon="person"
          label="My Profile"
          to="/users/me"
        />

        <MainNavigationItem
          v-if="canAccessRoute('/subscriptions') && hasPermission('isAccountAdmin')"
          data-t="billingLink"
          icon="attach_money"
          label="Billing &amp; Subscriptions"
          to="/subscriptions"
        />

        <MainNavigationItem
          v-if="hasPermission('accountView') && hasPermission('isAccountAdmin')"
          data-t="preferencesLink"
          icon="settings"
          label="Account Preferences"
          @click.native="setAccountSettingsDialogState(true)"
        />

        <MainNavigationItem
          v-if="canAccessRoute('/apps')"
          data-t="preferencesLink"
          icon="widgets"
          label="Apps"
          to="/apps"
        />

        <MainNavigationItem
          v-if="canAccessRoute('/groups')"
          data-t="groupsLink"
          icon="supervised_user_circle"
          label="Groups"
          to="/groups"
        />

        <MainNavigationItem v-if="canAccessRoute('/tags')" data-t="groupsLink" icon="label" label="Tags" to="/tags" />

        <MainNavigationItem
          v-if="canAccessRoute('/asset-types')"
          data-t="groupsLink"
          icon="supervised_user_circle"
          label="Asset Types"
          to="/asset-types"
        >
          <template #icon>
            <q-icon class="q-mr-sm" :name="assetsIcon" size="1.5rem" />
          </template>
        </MainNavigationItem>
      </q-expansion-item>

      <q-separator v-if="isAuthenticated" color="white" data-t="helpSeparator" />

      <MainNavigationItem
        v-if="$q.screen.xs"
        class="intercom-trigger"
        data-t="intercomLink"
        icon="support_agent"
        label="Get Help"
        to="/groups"
      />

      <MainNavigationItem
        v-if="$q.screen.xs"
        data-t="helpLink"
        icon="help"
        label="Help Center"
        @click.native="openURL('https://help.zubiecar.com')"
      />

      <MainNavigationItem icon="description" label="Release Notes" to="/release-notes" />

      <MainNavigationItem icon="gavel" label="Attributions" to="/attributions" />

      <MainNavigationItem v-if="!isAuthenticated" data-t="loginLink" icon="how_to_reg" label="Login" to="/login" />

      <MainNavigationItem
        v-if="isAuthenticated"
        data-t="logoutLink"
        icon="logout"
        label="Logout"
        @click.native="logout()"
      />

      <MainNavigationItem
        class="absolute-bottom-right no-pointer-events column min-height-none q-mb-xs text-weight-light text-right"
        :clickable="false"
        @click.stop
      >
        <div>v.{{ version }}</div>
        <div v-if="appBuild">b.{{ appBuild }}</div>
        <div v-if="liveUpdateBuild">lu.{{ liveUpdateBuild }}</div>
      </MainNavigationItem>
    </q-list>
  </q-drawer>
</template>

<script>
import { version } from 'app/package.json';
import { openURL } from 'quasar';
import { mapActions, mapGetters, mapState } from 'vuex';
import EventsFeedIcon from 'components/icons/EventsFeedIcon.vue';
import NotificationsBadge from 'components/notifications/NotificationsBadge.vue';
import SmartMaintenanceLink from 'components/SmartMaintenanceLink.vue';
import navigateToUrl from 'src/mixins/navigateToUrl';
import { ASSET_ICONS, ASSET_TYPES } from 'src/services/constants';
import { canAccessRoute } from 'src/services/router';
import MainNavigationItem from './MainNavigationItem.vue';

export default {
  mixins: [navigateToUrl],
  components: {
    EventsFeedIcon,
    MainNavigationItem,
    NotificationsBadge,
    SmartMaintenanceLink,
  },
  data() {
    return {
      version,
    };
  },
  computed: {
    ...mapGetters('notifications', ['areRecentUnread', 'recentUnread']),
    ...mapGetters('session', ['account', 'currentUser', 'hasPermission', 'isAuthenticated', 'isFleet', 'isRental']),
    ...mapState('app', ['deviceInfo', 'liveUpdateInfo', 'mainNavOpen']),
    ...mapState('env', ['appV2Url', 'carAdviseEnabledAccountIds']),
    ...mapState('session', ['account', 'accounts']),
    assetsIcon() {
      return ASSET_ICONS[ASSET_TYPES.ASSET];
    },
    appBuild() {
      return this.deviceInfo?.appBuild;
    },
    isIndividual() {
      return this.currentUser.isIndividual();
    },
    isMobile() {
      return this.$q.platform.is.mobile || this.$q.platform.is.capacitor;
    },
    liveUpdateBuild() {
      return this.liveUpdateInfo?.buildId;
    },
    isAllowedCarAdvise() {
      const { account = {}, carAdviseEnabledAccountIds = [], hasPermission } = this;
      return (
        carAdviseEnabledAccountIds.includes(account.id) ||
        hasPermission('smartMaintenanceView') ||
        hasPermission('smartMaintenancePreview')
      );
    },
    otherAccounts() {
      const accounts = this.accounts.filter(({ id }) => id !== this.account.id);
      return accounts.sort(({ nickname: n1 = '' }, { nickname: n2 = '' }) => {
        const first = n1.toLowerCase();
        const second = n2.toLowerCase();
        return first <= second ? -1 : 1;
      });
    },
  },
  methods: {
    openURL,
    ...mapActions('app', ['setMainNavOpen', 'setAccountSettingsDialogState']),
    ...mapActions('app', ['logout', 'switchAccount']),
    canAccessRoute(path) {
      return canAccessRoute(path);
    },
    onShow() {
      const onClickWhenMenuOpen = () => {
        // Do not toggle main nav if it's already closed
        if (this.mainNavOpen) {
          this.setMainNavOpen(false);
        }
        document.body.removeEventListener('click', onClickWhenMenuOpen);
      };
      document.body.addEventListener('click', onClickWhenMenuOpen);
    },
  },
  watch: {
    mainNavOpen() {
      if (this.mainNavOpen) {
        this.$refs.drawer.show();
      } else {
        this.$refs.drawer.hide();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.q-expansion-item :deep([role='button'] .q-item__label) {
  font-size: 1.3rem;
}

.q-expansion-item :deep([role='button'] .q-item__label--caption) {
  font-size: 0.9rem;
}

.q-expansion-item.text-white :deep(.q-item.disabled) {
  opacity: 100% !important;
}

.q-link {
  align-items: center;
  text-decoration: none;
}

.q-link:hover,
.q-link:active {
  background: $dark;
}

.q-item__label--header {
  font-size: 1.3rem;
}

.q-item__label--caption {
  font-size: 1em;
}

:deep(.text-white .q-item__section--side) {
  color: $white;
}

:deep(.notification-badge) {
  bottom: 13px;
  left: 7px;
}
</style>
