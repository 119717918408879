<template>
  <q-layout view="hHH LpR lFR">
    <q-header id="mainHeader" class="bg-primary print-hide" elevated>
      <MainToolbar />
    </q-header>
    <MainNavigationDrawer />

    <LightBox />

    <q-page-container :class="{ [pageContainerClass]: Boolean(pageContainerClass) }">
      <router-view v-slot="{ Component }">
        <keep-alive>
          <transition name="page-change">
            <component :is="Component" />
          </transition>
        </keep-alive>
      </router-view>
    </q-page-container>

    <DriverQuickNavigation v-if="isIndividual" />

    <account-settings-dialog v-if="accountSettingsDialogOpen"></account-settings-dialog>
    <EditAssetDialog v-if="editDialogOpen" :asset-key="editDialogKey" @hide="closeEditAssetDialog" />
  </q-layout>
</template>

<script>
import _debounce from 'lodash/debounce';
import { mapActions, mapGetters, mapState } from 'vuex';
import AccountSettingsDialog from 'components/account/AccountSettingsDialog.vue';
import EditAssetDialog from 'components/assets/EditAssetDialog.vue';
import DriverQuickNavigation from 'components/DriverQuickNavigation.vue';
import LightBox from 'components/LightBox.vue';
import MainNavigationDrawer from 'components/MainNavigationDrawer.vue';
import MainToolbar from 'components/MainToolbar.vue';
import mobileTitle from 'src/mixins/mobileTitle';

export default {
  name: 'MapLayout',
  mixins: [mobileTitle],
  components: {
    MainToolbar,
    MainNavigationDrawer,
    AccountSettingsDialog,
    DriverQuickNavigation,
    EditAssetDialog,
    LightBox,
  },
  computed: {
    ...mapState('assets', ['editDialogOpen', 'editDialogKey']),
    ...mapState('app', ['accountSettingsDialogOpen', 'broker']),
    ...mapGetters('session', ['currentUser', 'isCriticalDataLoaded']),
    pageContainerClass() {
      return this.$route.meta?.pageContainerClass;
    },
    isIndividual() {
      return this.currentUser.isIndividual();
    },
  },
  methods: {
    ...mapActions('assets', ['closeEditAssetDialog']),
    immediatelyStopPolling() {
      this.broker.stopApiPolling();
    },
  },
  created() {
    const ONE_MINUTE = 60000;
    this.stopPolling = _debounce(this.immediatelyStopPolling, ONE_MINUTE);
  },
  watch: {
    '$q.appVisible': function watchAppVisibility(isVisible) {
      if (!this.isCriticalDataLoaded) {
        // Do nothing, polling will begin once critical data is loaded
        return;
      }

      if (isVisible) {
        this.broker.startApiPolling();
        this.stopPolling.cancel();
      } else {
        this.stopPolling();
      }
    },
  },
};
</script>
