import _first from 'lodash/first';
import _isEmpty from 'lodash/isEmpty';
import _isFinite from 'lodash/isFinite';
import _isNil from 'lodash/isNil';
import { simpleStatuses, statusDisplays, ASSET_TYPES, ASSET_TYPE_NAMES, SHARE_TYPES } from 'src/services/constants';
import { getMarkerStatus } from 'src/services/geoJson';
import { getHeading, getStatus, hasDriverCheckin } from 'src/services/vehicles';
import Address from './Address';
import Device from './Device';
import Driver from './Driver';
import GeoJsonFeature from './GeoJsonFeature';
import SimpleModel from './SimpleModel';
import Tag from './Tag';

class Vehicle extends SimpleModel {
  /** @type {Object[]} */
  activeTroubleCodes = [];

  /** @type {Number} */
  batteryChargeLevel = null;

  /** @type {Number} */
  batteryVolts = null;

  /** @type {Device[]} */
  devices = [];

  /** @type {Number} */
  distanceFromTripEnd = null;

  /** @type {Driver} */
  driver = new Driver();

  /** @type {string} */
  primaryDriverKey = null;

  /** @type {Device} */
  firstConnectedDashcam = new Device();

  /** @type {Device} */
  firstConnectedDevice = new Device();

  /** @type {Number} */
  fuelLevel = null;

  /** @type {Number} */
  fuelEconomy = null;

  /** @type {string} */
  fuelEconomyUm = null;

  /** @type {string} */
  fuelType = null;

  /** @type {GeoJsonFeature} */
  geoJson = null;

  /** @type {Object[]} */
  groups = [];

  /** @type {String[]} */
  groupKeys = [];

  /** @type {Boolean} */
  hasActiveTroubleCodes = null;

  /** @type {Boolean} */
  hasDashcam = false;

  /** @type {Boolean} */
  hasLocationMismatch = null;

  /** @type {string} */
  imageUrl = null;

  /** @type {Boolean} */
  isConnected = null;

  /** @type {Boolean} */
  isVinEditable = null;

  /** @type {string} */
  key = null;

  /** @type {Address} */
  lastAddress = null;

  /** @type {import('src/types/vehicles-store').VehicleLastTrip | null} */
  lastTrip = null;

  /** @type {{ heading?: number | null; speedMph?: number; timestamp: string } | null} */
  location = null;

  /** @type {Boolean} */
  maintenanceDue = null;

  /** @type {string} */
  makeName = null;

  /** @type {string} */
  milStatus = null;

  /** @type {string} */
  modelName = null;

  /** @type {string} */
  modelYear = null;

  /** @type {string} */
  trimName = null;

  /** @type {string} */
  nickname = null;

  /** @type {Number} */
  odometer = null;

  /** @type {string} */
  odometerUm = null;

  /** @type {Boolean} */
  odometerIsActual = null;

  /** @type {number | null} */
  oilLife = null;

  /** @type {string | null} */
  plateExpiration = null;

  /** @type {string | null} */
  plateJurisdiction = null;

  /** @type {string | null} */
  plateNumber = null;

  /** @type {Boolean} */
  isMappable = null;

  /** @type {Boolean} */
  isOutOfBounds = null;

  /** @type {Boolean} */
  isVisible = null;

  /** @type {Boolean} */
  isVisibleWithoutTerm = null;

  /** @type {Object[]} */
  shares = [];

  /** @type {Number} */
  styleFuelEconomy = null;

  /** @type {string} */
  styleFuelEconomyUm = null;

  /** @type {string} */
  styleFuelType = null;

  /** @type {Tag[]} */
  tags = [];

  /** @type {String[]} */
  tagKeys = [];

  /** @type {VehicleTpms | null} */
  tpms = null;

  /** @type {string} */
  type = ASSET_TYPE_NAMES[ASSET_TYPES.VEHICLE];

  /** @type {string} */
  majorType = ASSET_TYPES.VEHICLE;

  /** @type {string} */
  vin = null;

  /** @type {string} */
  scheduleKey = null;

  /** @type {string} */
  updated = null;

  /** @type {string} */
  yearMakeModel = '';

  constructor(data = {}) {
    super();

    if (_isEmpty(data)) {
      return;
    }

    /** @type {{ tpms: VehicleTpms }} */
    const {
      batteryVolts,
      devices = [],
      driver,
      firstConnectedDashcam,
      firstConnectedDevice,
      geoJsonProps,
      lastAddress,
      tags = [],
      tpms = {},
    } = data;

    // Insert certain properties with their appropriate models
    data = {
      ...data,
      batteryVolts: _isFinite(batteryVolts) ? Math.floor(batteryVolts * 10) / 10 : null,
      devices: devices.map((device) => new Device(device)),
      driver: new Driver(driver || {}),
      firstConnectedDashcam: firstConnectedDashcam ? new Device(firstConnectedDashcam) : new Device(),
      firstConnectedDevice: firstConnectedDevice ? new Device(firstConnectedDevice) : new Device(),
      lastAddress: new Address(lastAddress),
      tags: tags.map((tag) => new Tag(tag)),
      tpms: {
        ...tpms,
        leftFront: tpms?.leftFront ? Math.round(tpms.leftFront) : 0,
        leftRear: tpms?.leftRear ? Math.round(tpms.leftRear) : 0,
        rightFront: tpms?.rightFront ? Math.round(tpms.rightFront) : 0,
        rightRear: tpms?.rightRear ? Math.round(tpms.rightRear) : 0,
      },
    };

    this.insert(data);

    // Update GeoJSON after data is inserted
    if (this.location) {
      this.geoJson = new GeoJsonFeature([this.location.longitude, this.location.latitude], {
        key: this.key,
        nickname: this.nickname || 'Unnamed Vehicle',
        tagColor: this.firstTagColor,
        majorType: ASSET_TYPES.VEHICLE,
        ...geoJsonProps,
      });
    }
  }

  /**
   * Returns the first disconnected device.
   *
   * @returns {Device}
   */
  get firstDisconnectedDevice() {
    const disconnectedDevices = this.devices.filter(({ isConnected }) => isConnected === false);
    return _first(disconnectedDevices) || new Device();
  }

  /**
   * Returns the first tag color that matches the tag presets.
   *
   * @returns {string} Tag hex color value or "none".
   */
  get firstTagColor() {
    return this.tags?.[0]?.color || 'none';
  }

  /**
   * Generates and returns the Vehicle's marker icon ID.
   *
   * @returns {string}
   */
  get geoJsonMarkerId() {
    return `marker-${this.geoJson.properties.motionStatus}`;
  }

  /**
   * Returns true if the vehicle/device supports battery health.
   */
  get hasBatteryHealth() {
    return this.firstConnectedDevice.model !== 'Toyota Direct Connect';
  }

  /**
   * Returns true if the vehicle/device supports DTCs.
   */
  get hasDtcs() {
    return this.firstConnectedDevice.model !== 'Toyota Direct Connect';
  }

  /**
   * Returns true if the vehicle has a battery charge level.
   */
  get hasElectricFuel() {
    return !_isNil(this.batteryChargeLevel);
  }

  /**
   * Returns true if the vehicle has a gas fuel value.
   */
  get hasGasFuel() {
    return !_isNil(this.fuelLevel);
  }

  /**
   * Returns true if a device supports starter prevention.
   *
   * @returns {boolean}
   */
  get hasStarterPrevention() {
    return this.devices.some(({ model }) => (model || '').startsWith('XT25'));
  }

  /**
   * Returns true if the vehicle has TPMS data.
   */
  get hasTpms() {
    const { leftFront, leftRear, rightFront, rightRear } = this.tpms || {};
    return Boolean(leftFront || leftRear || rightFront || rightRear);
  }

  /**
   * Returns true (vehicles always have a trips device).
   */
  get hasTripsDevice() {
    return true;
  }

  /**
   * Returns true if the vehicle is considered to be currently in-trip.
   */
  get isInTrip() {
    return Boolean(this.lastTrip?.startPoint && this.location?.motionStatus === simpleStatuses.MOVING);
  }

  /**
   * Determines if a vehicle is moving.
   *
   * @returns {Boolean}
   */
  get isMoving() {
    return this.location?.motionStatus === simpleStatuses.MOVING;
  }

  /**
   * Returns trips route.
   */
  get tripsRoute() {
    return 'vehicleTrips';
  }

  /**
   * Determines and retrieves appropriate status for the vehicle/device.
   *
   * @returns {string}
   */
  get status() {
    /**
     * Exract this function so that StatusFilter can reuse it and execute it in a worker)
     * Including the Tag model breaks worker compilation b/c it includes the whole store, which usees browser globals
     */
    return getStatus.call(this);
  }

  /**
   * Retrieves the user friendly display of the status.
   */
  get statusDisplay() {
    return statusDisplays[this.status] || statusDisplays.UNKNOWN;
  }

  /**
   * Returns the vehicle's icon ID.
   *
   * @returns {string}
   */
  get iconId() {
    return this.geoJson.properties.motionStatus;
  }

  /**
   * Generates a GeoJSON "Feature" to be used in a GeoJSON layer.
   *
   * @returns {Object} GeoJSON Feature
   */
  toGeoJson() {
    return JSON.parse(JSON.stringify(this.geoJson));
  }

  /**
   * Returns the "heading" (if applicable).
   *
   * @returns {Number|null}
   */
  getHeading() {
    return getHeading(this.location);
  }

  /**
   * Returns the motion status based on the location motion status, speed,
   * and whether or not the vehicle is connected.
   *
   * @return {String|null}
   */
  getMarker() {
    return getMarkerStatus.call(this);
  }

  /**
   * Returns whether the curent driver is the result of an active vehicle checkin
   *
   * @return {Boolean}
   */
  get hasDriverCheckin() {
    return hasDriverCheckin(this.driver);
  }

  /**
   * Returns the most recently created share of any type
   *
   * @return {Object|null}
   */
  get share() {
    if (!this.shares || this.shares.length === 0) {
      return null;
    }
    return this.shares[this.shares.length - 1];
  }

  /**
   * Returns the most recently created recovery mode share
   *
   * @return {Object|null}
   */
  get recoveryShare() {
    if (!this.shares || this.shares.length === 0) {
      return null;
    }
    const filtered = [...this.shares].reverse().filter(({ type }) => type === SHARE_TYPES.RECOVERY);
    return filtered.length > 0 ? filtered[0] : null;
  }

  /**
   * Returns the most recently created repossession/Collateral Consultants share
   *
   * @return {Object|null}
   */
  get repossessionShare() {
    if (!this.shares || this.shares.length === 0) {
      return null;
    }
    const filtered = [...this.shares].reverse().filter(({ type }) => type === SHARE_TYPES.REPOSSESSION);
    return filtered.length > 0 ? filtered[0] : null;
  }

  /**
   * Returns the name of the route for this type of entity that corresponds to the currently active tab in the detailed assets/vehicles UI
   *
   * @returns {string}
   */
  static getTabRoute(currentRoute) {
    switch (currentRoute) {
      case 'asset':
      case 'vehicle':
        return 'vehicle';
      case 'assetLocations':
      case 'assetTrips':
      case 'vehicleTrips':
        return 'vehicleTrips';
      default:
        return currentRoute;
    }
  }
}

export default Vehicle;
