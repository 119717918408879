<template>
  <ZubieDialog ref="dialog" :maximized="$q.screen.xs" ok-only :processing="isLoading" width="500px">
    <template #title>
      <div class="row justify-between items-center">
        <div>Recovery Mode for Lost or Stolen Vehicle</div>
        <q-spinner v-if="isLoading && !isInRecoveryMode" color="white" />
      </div>
    </template>
    <q-banner v-if="isInRecoveryMode" class="q-mb-md q-py-md bg-positive text-white" square>
      <template #avatar>
        <q-icon name="policy" size="50px" />
      </template>
      <div class="row items-center">
        <div class="enabled-header text-h5 line-height-1">Recovery Mode enabled</div>
        <span v-if="link">Track the vehicle on the Live Map or with the link below.</span>
        <span v-else class="q-mt-sm"
          >The current location is not available yet. Please watch for the vehicle to appear on the Live Map or the
          Vehicle Overview page.</span
        >
      </div>
    </q-banner>
    <div class="q-mb-sm text-h6">
      <div class="text-caption text-grey-7 line-height-1">Lost/Stolen Vehicle</div>
      {{ vehicle.nickname || 'Unnamed Vehicle' }}
    </div>
    <ExistingShareCard
      :id="vehicleKey"
      ref="existingShareCard"
      flat
      source="recovery-mode"
      type="vehicle"
      @loaded="onExistingShareLoaded"
    >
      <template #after>
        <q-banner v-if="newlyReported && link" class="location-link" square>
          <strong>Note:</strong> This link will expire in 24 hours. Anyone with the link can see the vehicle’s location,
          even if they do not have a Zubie account.
        </q-banner>
      </template>
    </ExistingShareCard>
    <div v-if="isLoading && isInRecoveryMode" class="loading-area row justify-center items-center q-mb-md">
      <q-spinner color="primary" size="xl" />
    </div>
    <div v-if="!isInRecoveryMode" class="q-my-sm">
      If you believe this vehicle has been lost or stolen, you can set the device to Recovery Mode which sends location
      updates more frequently, even when the vehicle is turned off. Please use the share feature for all other
      situations.
    </div>
    <q-banner v-if="!isInRecoveryMode" class="q-py-md q-mb-sm bg-info text-brown-10" square>
      <template #avatar>
        <q-icon class="warning-icon" color="white" name="warning" />
      </template>
      <div>
        Recovery mode and the associated sharing link will be activated for 24 hours. We do not leave Recovery Mode on
        for extended periods of time because it may drain the vehicle's battery.
      </div>
    </q-banner>
    <div class="row justify-end">
      <q-btn
        v-if="!isInRecoveryMode"
        class="q-mb-sm"
        color="white"
        :disable="Boolean(isLoading || link)"
        label="Enable Recovery Mode"
        :loading="isEnabling"
        square
        text-color="primary"
        type="button"
        @click.prevent="submit"
      />
    </div>
    <q-banner v-if="isInRecoveryMode" class="q-py-md bg-silver" square>
      <template #avatar>
        <q-icon color="dark" name="info" />
      </template>
      You may reactivate recovery mode (and create a new recovery sharing link) if needed after 24 hours expires. Please
      contact support to restore the vehicle to active mode when you have recovered it.
    </q-banner>
  </ZubieDialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ExistingShareCard from 'components/ExistingShareCard.vue';
import Vehicle from 'src/models/Vehicle';
import { copy } from 'src/services/clipboard';
import { fit } from 'src/services/cloudinary';
import { dayjs } from 'src/services/date';

export default {
  name: 'RecoveryModeDialog',
  props: {
    vehicleKey: {
      required: true,
      type: String,
    },
  },
  components: {
    ExistingShareCard,
  },
  computed: {
    ...mapState('env', ['locatorUrl']),
    ...mapState('vehicles', ['vehicles']),
    isInRecoveryMode() {
      return Boolean(this.recoveryShare?.id);
    },
    link() {
      const { id } = this.recoveryShare;
      return id ? `${this.locatorUrl}/${id}` : null;
    },
    recoveryShare() {
      return this.vehicle?.recoveryShare || {};
    },
    vehicle() {
      return this.vehicles[this.vehicleKey] || new Vehicle();
    },
  },
  data() {
    return {
      isEnabling: false,
      isLoading: true,
      newlyReported: false,
    };
  },
  methods: {
    ...mapActions('vehicles', ['createShare', 'getLocationShares', 'reportLostOrStolenVehicle']),
    copy() {
      copy(this.link).catch(() => {});
    },
    onExistingShareLoaded() {
      this.isLoading = false;
    },
    async submit() {
      this.isEnabling = true;

      const body = {
        expirationTimestamp: dayjs().add(24, 'hours').valueOf(),
        message: '',
        source: 'recovery-mode',
      };
      const imageUrl = this.vehicle.imageUrl || '';
      if (imageUrl) {
        body.image = fit(imageUrl, {
          width: 90,
          height: 90,
        });
      }
      if (this.vehicle) {
        body.vehicleKey = this.vehicleKey;
      }
      const response = await this.createShare(body);
      if (response.error) {
        let error = response?.metadata?.body || 'Unable to create a location share';

        if (error.includes('No location data')) {
          error = 'Unable to create a location share: this vehicle has no location';
        }

        this.$q.notify({
          icon: 'error',
          color: 'negative',
          message: error,
        });
      }

      if (this.vehicle) {
        this.isLoading = true;
        this.newlyReported = true;
        this.$refs.existingShareCard.getShare();
      }

      const result = await this.reportLostOrStolenVehicle({ link: this.link, vehicleKey: this.vehicleKey });

      if (!result) {
        this.$q.notify({
          icon: 'error',
          color: 'negative',
          message:
            'An unexpected error has occurred while trying to report this lost/stolen vehicle. Please contact support directly to recover this vehicle.',
        });
      }

      const { data } = result || {};
      if (data?.status === 'ALREADY_CONFIGURED') {
        this.$q.notify({
          icon: 'error',
          color: 'negative',
          message: 'This device is already in recovery mode',
        });
      }

      this.isEnabling = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.enabled-header {
  @media (max-width: 499px) {
    margin-bottom: map-get($space-sm, 'y');
  }
}

.loading-area {
  min-height: 136px;
}

.location-link {
  border: 1px solid $grey-3;
}

.warning-icon {
  text-shadow: 1px 1px $lime-10;
}
</style>
